import Head from "next/head";
import Container from "@components/Ui/Container";

export default () => (
  <>
    <Head>
      <title>Sesamy Portal - Page not found</title>
    </Head>
    <Container className="px-12 py-14">
      <div className="text-4xl font-semibold">404</div>
      <div className="text-2xl">Page could not be found</div>
    </Container>
  </>
);

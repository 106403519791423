import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  children?: ReactNode;
  className?: string;
  bgColor?: string;
  borderRadius?: "lg" | "xl";
};

export default ({
  children,
  className = "",
  bgColor = "bg-gray-800",
  borderRadius = "xl",
}: Props) => (
  <div
    className={twMerge(
      "w-full",
      borderRadius === "lg" && "rounded-lg",
      borderRadius === "xl" && "rounded-xl",
      bgColor,
      className,
    )}
  >
    {children}
  </div>
);
